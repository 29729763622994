/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Modal from 'react-modal';
import Image from '../Image/Image';
import Button from '../Button/Button';

function SucessModal(props) {
    const { campaignCashback, isXSmall, handleClose, sucessModalOpen, vocherCode } = props;
    const labels = campaignCashback?.fields?.successFormConstanst?.fields;

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.7)',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '850px',
            width: isXSmall ? '' : '100%',
            backgroundColor: '#0084c9',
            overflow: 'auto',
            inset: '50% auto auto 50%',
            height: '100%'
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        handleClose();
    }
    const noonClick=(url)=>{
        window.open(url);
    }
    return <Modal
        isOpen={sucessModalOpen}
        onRequestClose={handleClose}
        closeTimeoutMS={250}
        style={customModalStyles}>
        <form name="emailForm" className="emailForm" onSubmit={(e) => handleSubmit(event)} >
            <div className='form-wrapper'>
                <h2 className='titleCahback'>{labels?.title}</h2>
                <div className='subtitle-sucess'>{labels?.subtitle}</div>
                <div className="vocher center-text">{labels?.couponCode}</div>
                <div className="center-text screenshot-msg">{labels?.screenShotMsg}</div>
                <div className='subtitle-sucess'>{labels?.noonTitle}</div>
                <div className="center-text">
                <div className="image-noon" onClick={()=>noonClick(labels?.noonUrl)}>
                    <Image
                        image={labels?.noonImage}
                    />
                </div>
                </div>
                <div className='subtitle-sucess'>{labels?.text}</div>
                <div className='subtitle-sucess'>{labels?.scanText}</div>
                <div className="center-text">
                    <Image
                        image={labels?.qrCodeImage}
                    />
                </div>
                <div className='submit-btn-wrapper'>
                    <fieldset>
                        <Button className="button-finish">
                            {labels?.finishBtn}
                        </Button>
                    </fieldset>
                </div>
            </div>
        </form>
    </Modal>
}

export default mediaQueryHOC(SucessModal)

SucessModal.propTypes = {
    handleClose: PropTypes.func,
    isXSmall: PropTypes.bool,
    campaignCashback: PropTypes.object,
    sucessModalOpen: PropTypes.bool,
    vocherCode:PropTypes.string
}

SucessModal.defaultProps = {
    isModelOpen: false
}