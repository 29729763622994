/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { gcsCashbackFormRegister } from '../../adapters/model/service/gcs/gcsService';
import { customJsonstringify } from '../../adapters/helpers/Utils';
import { CashbackBlockConstants } from '../../adapters/helpers/Constants';
import Modal from 'react-modal';
import validate from './validate'
import Button from '../Button/Button';
function ClicRegister(props) {
    const { campaignCashback, isXSmall, handleClose, getVocherCode, handleSucessModal } = props;
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.7)',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '850px',
            width: isXSmall ? '' : '100%',
            backgroundColor: '#0084c9',
            overflowY: 'scroll',
            inset: '50% auto auto 50%',
            height: '100%'
        }
    };

    const cashbackGenerateRegisterPayload = () => {   
        const registrationPyload = customJsonstringify({
            'campaign': {
                'campaignId': parseInt(process.env.CASHBACK_API_PROGRAM_ID),
                'locale': process.env.CASHBACK_API_LOCALE
            },
            'consumer': {
                'telephoneNumber': areaCodeOption + phoneNumber,
                'firstName': firstName,
                'lastName': lastName,
                'gender': selectedGenderOption === 'M' ? CashbackBlockConstants.male : CashbackBlockConstants.female,
                'emailAddress': emailInput,
                'metaData': [
                    {
                        'Attribute': CashbackBlockConstants.age,
                        'AttributeValue': ageValue,
                    }
                ]
            },
            'order': {
                'surveyId': parseInt(process.env.CASHBACK_API_SURVEY_ID),
                'optIns': process.env.CASHBACK_OPT_IN_ID,
            },
            'address': [
                {
                    'addressTypeCode': CashbackBlockConstants.addressTypeCode,
                    'countryISOCode':  selectedCountryOption === CashbackBlockConstants.saudi ? CashbackBlockConstants.SAU : selectedCountryOption === CashbackBlockConstants.UAE ? CashbackBlockConstants.UAE : null,
                    'city': cityValue,
                }
            ],
            'surveyResponse': [
                {
                    'questionId': parseInt(process.env.CASHBACK_API_QUESTION),
                    'optionId': campaignValues === CashbackBlockConstants.dentalClinc ?  parseInt(process.env.CASHBACK_API_OPTION_DENTAL) :  parseInt(process.env.CASHBACK_API_OPTION_SOCIAL),
                },
                {
                    'questionId': parseInt(process.env.CASHBACK_API_QUESTIONID_TEXT),
                    'answer': dentalClinicName,
                }
            ]

        })

        return registrationPyload
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (event) {
            event.preventDefault();
        }
        const validationErrors = validate(
            firstName, lastName, emailInput, areaCodeOption, phoneNumber, selectedGenderOption, selectedCountryOption,
            ageValue, cityValue, campaignValues, smsCheckbox, emailCheckbox);
        const errors = Object.keys(validationErrors).some(k => validationErrors[k]);
        seterrorValidation(validationErrors);
        if (!errors) {
            try {
                const registerResponse = await gcsCashbackFormRegister(cashbackGenerateRegisterPayload())
                if (registerResponse.code === CashbackBlockConstants.success && registerResponse.voucher !== null) {
                    getVocherCode(registerResponse.voucher);
                    handleClose();
                    handleSucessModal();
                }
                if (registerResponse?.error?.message !== null) {
                    setErrorMessageFromAPI(registerResponse?.error?.message)
                }
            } catch (error) {
                console.error('cashback error=>', error)
            }
        }
    }

    const [selectedCountryOption, setSelectedCountryOption] = useState('');
    const [errorMessageFromAPI, setErrorMessageFromAPI] = useState(null)
    const [areaCodeOption, setAreaCodeOption] = useState('');
    const [ageValue, setAgeValue] = useState('');
    const [selectedGenderOption, setSelectedGenderOption] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [campaignValues, setCampaignValues] = useState('')
    const [smsCheckbox, setSmsCheckbox] = useState('')
    const [emailCheckbox, setEmailCheckbox] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailInput, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [dentalClinicName, setDentalClinicName] = useState('')
    const [errorValidation, seterrorValidation] = useState('')
    const {
        title, firstNamePlaceholder, lastNamePlaceholder, firstNameErrorText, lastNameErrorText,
        email, emailError, areacodeValues, areacodeErrorText, phoneNumberErrorText, countryErrortext, genderErrorText, checkboxErrorText, ageErrorText, cityErrorTExt, campainErrortext
    } = campaignCashback?.fields;
    return <Modal
        isOpen={true}
        onRequestClose={handleClose}
        closeTimeoutMS={250}
        style={customModalStyles}>
        <form name="emailForm" className="emailForm" onSubmit={(e) => handleSubmit(event)} >
            <div className='form-wrapper'>
                <h2 className='titleCahback'>{title}</h2>
                <div className='registerFormWrapper'>
                    <fieldset className='cashback-fieldsForm'>
                        {errorMessageFromAPI && <span className="validator" >{errorMessageFromAPI}</span>}
                        <div className="modal-row">
                            <div className="modal-col col-margin-right">
                                <input type="text" className="form-control" value={firstName} name={firstNamePlaceholder} id={firstNamePlaceholder} refs={firstNamePlaceholder} placeholder={firstNamePlaceholder} onChange={(e) => setFirstName(event.target.value)}
                                />
                                {errorValidation.firstName && <span className="validator" >{firstNameErrorText}</span>}

                            </div>
                            <div className="modal-col">
                                <input type="text" className="form-control" value={lastName} placeholder={lastNamePlaceholder} onChange={(e) => setLastName(event.target.value)} />
                                {errorValidation.lastName && <span className="validator">{lastNameErrorText}</span>}
                            </div>
                        </div>
                        <div className="modal-row">
                            <div className="modal-col col-margin-right">
                                <input type="text" className="form-control" value={emailInput} placeholder={email} onChange={(e) => setEmail(event.target.value)} />
                                {errorValidation.emailInput && <span className="validator" >{emailError}</span>}
                            </div>
                            <div className="modal-col">
                                <select value={areaCodeOption} className="area-code-select" onChange={(e) => { setAreaCodeOption(e.target.value) }}>
                                    {
                                        areacodeValues.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }

                                </select>

                                <input type="number" className="form-control phone-number-select" value={phoneNumber} placeholder={campaignCashback?.fields?.phoneNumber} onChange={(e) => setPhoneNumber(event.target.value)} />
                                <div>
                                    {errorValidation.areaCodeOption && <span className="validator validator-area-code">{areacodeErrorText}</span>}
                                    {errorValidation.phoneNumber && <span className="validator validator-area-code">{phoneNumberErrorText}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-row">
                            <div className="modal-col col-margin-right">
                                <select value={selectedGenderOption} onChange={(e) => { setSelectedGenderOption(e.target.value) }}>
                                    {
                                        campaignCashback?.fields?.gender.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }
                                </select>
                                {errorValidation.selectedGenderOption && <span className="validator">{genderErrorText}</span>}
                            </div>
                            <div className="modal-col">
                                <select value={selectedCountryOption} onChange={(e) => { setSelectedCountryOption(e.target.value) }}>
                                    {
                                        campaignCashback?.fields?.country.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }
                                </select>
                                {errorValidation.selectedCountryOption && <span className="validator">{countryErrortext}</span>}
                            </div>
                        </div>
                        <div className="modal-row">
                            <div className="modal-col col-margin-right">
                                <select value={ageValue} onChange={(e) => { setAgeValue(e.target.value) }}>
                                    {
                                        campaignCashback?.fields?.age.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }
                                </select>
                                {errorValidation.ageValue && <span className="validator">{ageErrorText}</span>}
                            </div>
                            <div className="modal-col">
                                <select value={cityValue} disabled={selectedCountryOption === 'Saudi' || selectedCountryOption === 'Others'} onChange={(e) => { setCityValue(e.target.value) }}>
                                    {
                                        campaignCashback?.fields?.city.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }
                                </select>
                                {errorValidation.cityValue && <span className="validator">{cityErrorTExt}</span>}
                            </div>
                        </div>
                        <div className="modal-row">
                            <div className="modal-col col-margin-right">
                                <p className="agree-label-text">{campaignCashback?.fields?.feedbackheader}</p>
                                <select value={campaignValues} onChange={(e) => { setCampaignValues(e.target.value) }}>
                                    {
                                        campaignCashback?.fields?.campaignValues.split(',').map((i, j) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })
                                    }
                                </select>
                                {errorValidation.campaignValues && <span className="validator">{campainErrortext}</span>}
                            </div>
                            <div className="modal-col">
                                <p className="agree-label-text">{campaignCashback?.fields?.dentalClinicName}</p>
                                <input type="text" className="form-control" value={dentalClinicName} onChange={(e) => setDentalClinicName(event.target.value)} />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className='registerFormWrapper'>
                    <div dangerouslySetInnerHTML={{ __html: campaignCashback?.fields?.promotionRulesAndTerms }} />

                    <div className='agreeWrapper'>
                        <label className="agree-label">
                            <input type="checkbox" name="agree" id="myCheck" value={smsCheckbox} onChange={setSmsCheckbox} />

                            <span className="agree-label-text left-agree-checkbox" dangerouslySetInnerHTML={{ __html: campaignCashback?.fields?.smsLabelCheckbox }}></span>

                        </label>
                        {errorValidation.smsCheckbox && <span className="validator">{checkboxErrorText}</span>}
                    </div>
                    <div className='agreeWrapper'>
                        <label className="agree-label">
                            <input type="checkbox" name="agree" id="myCheck" value={emailCheckbox} onChange={setEmailCheckbox} />

                            <span className="agree-label-text left-agree-checkbox" dangerouslySetInnerHTML={{ __html: campaignCashback?.fields?.emailtextLabelCheckbox }}></span>

                        </label>
                        {errorValidation.emailCheckbox && <span className="validator">{checkboxErrorText}</span>}
                    </div>

                    <div className='submit-btn-wrapper'>
                        <fieldset>
                            <Button className="button-finish">
                                {campaignCashback?.fields?.submitbtn}
                            </Button>
                        </fieldset>
                    </div>
                </div>
            </div>
        </form>
    </Modal>
}

export default mediaQueryHOC(ClicRegister)

ClicRegister.propTypes = {
    handleClose: PropTypes.func,
    getVocherCode: PropTypes.func,
    handleSucessModal: PropTypes.func,
    isModelOpen: PropTypes.bool,
    isXSmall: PropTypes.bool,
    campaignCashback: PropTypes.object
}

ClicRegister.defaultProps = {
    isModelOpen: false
}