import React, { useRef, useEffect, useCallback } from 'react';
import C from 'classnames';
import PropTypes from 'prop-types';

import { useIsomorphicLayoutEffect } from '../../adapters/helpers/Hooks';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import {UnderlineTabsNavigationConstants} from '../../adapters/helpers/Constants';

function UnderlineTabsNavigation({tabs, selectedIndex, isMedium, scrollToContent}) {
    const tabsRef = useRef(null);

    const runScrollTo = useCallback((element, options) => {
        if(tabsRef.current) {
            const elementRect = element.getBoundingClientRect();
            const elementWidth = elementRect.right - elementRect.left;
            const windowWidth = window.innerWidth;
            const leftMargin = (windowWidth - elementWidth) / 2;
            const dy =  elementRect.left - leftMargin;
            const nextY = tabsRef.current.scrollLeft + dy;
            if(tabsRef.current.scrollTo) {
                tabsRef.current.scrollTo({ left: nextY, ...options});
            } else {
                tabsRef.current.scrollLeft = nextY;
            }
        }
    }, []);

    const onResize = useCallback(() => {
        if(tabsRef.current) {
            const activeTabQuery = tabsRef.current.querySelector('.ob-underline-tabs-navigation-tab--active');
            if(activeTabQuery) {
                runScrollTo(activeTabQuery);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener(UnderlineTabsNavigationConstants.resize, onResize);
        return () => window.removeEventListener(UnderlineTabsNavigationConstants.resize, onResize)
    }, []);

    useIsomorphicLayoutEffect(() => {
        onResize();
    }, [tabsRef.current]);

    useEffect(() => {
        if(tabsRef.current && !isMedium) {
            tabsRef.current.scrollLeft = 0;
        }
    }, [isMedium])

    return (
        <div ref={tabsRef} className="ob-underline-tabs-navigation" role={UnderlineTabsNavigationConstants.tablist} aria-label={UnderlineTabsNavigationConstants.childLabel}>
            {tabs.map((tab, i) => (
                <div role={UnderlineTabsNavigationConstants.tab} key={i}>
                    <a key={i}
                   href={tab?.link}
                   onClick={event => scrollToContent(event, tab?.link)}
                   className={C('event_internal_link ob-underline-tabs-navigation-tab', {
                       'ob-underline-tabs-navigation-tab--active': i === selectedIndex, })}
                   data-action-detail={tab?.text}
                    >
                        <span className={'ob-underline-tabs-navigation-tab__content'}>{tab?.text}</span>
                    </a>
                </div>
            ))}
        </div>
    )
}

export default mediaQueryHOC(UnderlineTabsNavigation);

UnderlineTabsNavigation.propTypes = {
    tabs: PropTypes.any,
    selectedIndex: PropTypes.any,
    isMedium: PropTypes.bool,
    scrollToContent: PropTypes.func
}
