import React, { useState, useEffect, useRef } from 'react';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import Heading from '../Heading/Heading';
import Icon from '../Icon/Icon';
import {smoothVerticalScroll} from '../../adapters/helpers/Utils';


export default function FeaturesTab(props) {
    const { extraAttributes } = props;

    return extraAttributes?.entity?.featuresTab ? renderFeaturesTabBlock(extraAttributes.entity.featuresTab) : '';
}

function renderFeaturesTabBlock(featuresTab) {

    const block = new Block(featuresTab);

    const value = name => {
        return block.getFieldValue(name);
    };

    const [activeTab, setActiveTab] = useState(0);
    const featureRef = useRef(null);

    useEffect(() => {
        if (window) {
            window.addEventListener('hashchange', selectFeatureFromUrl);
            return () => {
                window.removeEventListener('hashchange', selectFeatureFromUrl);
            }
        }
    });

    const selectFeatureFromUrl = () => {
        const hashIndex = parseInt(location.hash.substring(location.hash.length - 1), 10);
        if (!isNaN(hashIndex) && hashIndex <= (features.length - 1)) {
            if (featureRef?.current) {
                scrollToTab(hashIndex, `#${anchorId}`);
            }
        }
    };

    const scrollToTab = (featureIndex, elemSelector) => {
        setActiveTab(featureIndex);
        const targetElement = document.querySelector(elemSelector);
        const subNav = document.querySelector('.ob-product-sub-nav__container');
        const subNavHeight = subNav?.offsetHeight ? subNav.offsetHeight : 0;
        smoothVerticalScroll(targetElement, 'top', subNavHeight);
    };

    let className = 'ob-features-tab ' + block.getFieldValue('classNames');

    const features = value('features');
    const anchorId = value('anchorId');

    return (
        <div className={className} id={anchorId} ref={featureRef}>
            {/* START MOBILE LAYOUT */}
            <ul className={'ob-features-tab__wrapper ob-features-tab--mobile'}>
                {
                    features.map((feature, featureIndex) => (
                        <li className={`ob-features-tab__item ${feature?.fields?.classNames} ${featureIndex === activeTab ? 'is-active' : ''}`} key={`feature-tab-content-${featureIndex}`}>
                            <button
                                onClick={() => scrollToTab(featureIndex,'.ob-features-tab__item.is-active .ob-features-tab__tabs-button')}
                                className={`event_button_click ob-features-tab__tabs-button ${featureIndex === activeTab ? 'is-active' : ''}`}
                                data-action-detail={feature.fields.shortTitle}
                                id={`accordion-control-${featureIndex}`}
                                aria-expanded={featureIndex === activeTab}
                                aria-disabled={featureIndex === activeTab}
                                aria-controls={`features-tab-content-${featureIndex}`}
                                >
                                <span>
                                    {feature.fields.shortTitle}
                                </span>
                                <span className={'ob-features-tab__tabs-button-icon'}
                                      aria-hidden="true">
                                    {featureIndex === activeTab ? (<Icon name="chevronTop" />) : (<Icon name="chevronDown" />)}
                                </span>
                            </button>
                            <div
                                className={`ob-features-tab__content-text ${featureIndex === activeTab ? 'is-active' : ''}`}
                                id={`features-tab-content-${featureIndex}`}
                                aria-labelledby={`accordion-control-${featureIndex}`}
                                aria-hidden={featureIndex !== activeTab}
                                role="region">
                                <Image image={feature.fields.mobileBackgroundAsset ? feature.fields.mobileBackgroundAsset : feature.fields.backgroundAsset}>
                                    {featureIndex === activeTab &&
                                        <div className={'ob-features-tab__text'}
                                             data-white-text={feature.fields.hasWhiteText.toString()}>
                                            <Heading tag="h2">{feature.fields.title}</Heading>
                                            <p className={'ob-features-tab__subtitle'}>{feature.fields.subtitle}</p>
                                            <p className={'ob-features-tab__description'}
                                               dangerouslySetInnerHTML={{__html: feature.fields.description}}></p>
                                        </div>
                                    }
                                </Image>
                            </div>
                        </li>
                    ))
                }
            </ul>
            {/* END MOBILE LAYOUT */}

            {/* START DESKTOP LAYOUT */}
            <div className={'ob-features-tab--desktop'}>
                <div className={'ob-features-tab__tabs-wrapper'} role="tablist">
                    {
                        features.map((feature, featureIndex) => (
                            <div className={'ob-features-tab__tabs-item'} key={`feature-tab-${featureIndex}`}>
                                <button
                                    onClick={() => setActiveTab(featureIndex)}
                                    className={`event_button_click ob-features-tab__tabs-button ${featureIndex === activeTab ? 'is-active' : ''}`}
                                    data-action-detail={feature.fields.shortTitle}
                                    role="tab"
                                    aria-selected={featureIndex === activeTab}
                                    aria-controls={`feature-tab-content-${featureIndex}`}
                                    id={`feature-tab-${featureIndex}`}
                                    >
                                    {feature.fields.shortTitle}
                                </button>
                            </div>
                        ))
                    }
                </div>
                <div className={'ob-features-tab__content-wrapper'}>
                    {
                        features.map((feature, featureIndex) => (
                            <div
                                className={`ob-features-tab__content-item ${feature?.fields?.classNames} ${featureIndex === activeTab ? 'is-active' : ''}`}
                                data-white-text={feature.fields.hasWhiteText.toString()}
                                key={`feature-tab-content-${featureIndex}`}
                                id={`feature-tab-content-${featureIndex}`}
                                role="tabpanel"
                                aria-hidden={featureIndex !== activeTab}
                                aria-labelledby={`feature-tab-${featureIndex}`}
                                tabIndex={featureIndex === activeTab ? 0 : -1}>
                                <Image image={feature.fields.backgroundAsset} />
                                {featureIndex === activeTab &&
                                    <div className={'ob-features-tab__content-text'}
                                         data-text-align={feature.fields.textAlign}>
                                        <Heading tag="h2">{feature.fields.title}</Heading>
                                        <p className={'ob-features-tab__subtitle'}>{feature.fields.subtitle}</p>
                                        <p className={'ob-features-tab__description'}
                                           dangerouslySetInnerHTML={{__html: feature.fields.description}}></p>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* END DESKTOP LAYOUT */}
        </div>
    );
}
