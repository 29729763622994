import React, {useState} from 'react';
import useJanrainGlobalState from '../janrainForms/useJanrainGlobalState';
import {janrainInit} from '../janrainForms/Janrain.helpers';
import {FakeJanrainScreen} from '../janrainForms';

import {useIsomorphicLayoutEffect} from '../../../../adapters/helpers/Hooks';
import {redirectTo} from '../../helpers/redirect.helpers';
import {validateCustomSessionTimeoutActive} from '../../helpers/localstorage.helpers';

export const collectFormErrors = (flow, response) => {
    const allFields = flow.fields
    const validatedForm = allFields[response.form]

    let errorObj = {
        form: response.form,
        errors:[]
    }

    if(validatedForm.errors !== undefined){
        onErrorInForm(validatedForm, errorObj)
    } else {
        onErrorInFields(allFields, errorObj)
    }
    return errorObj
}

const onErrorInForm = (form, errorObj) => {
    const message = Object.keys(form.errors).map(key => form.errors[key]).toString()
    const error = {
        message: message
    }
    errorObj.errors.push(error)
}

const onErrorInFields = (fields, errorObj) => {
    for (const field in fields){
        if(fields.hasOwnProperty(field)) {
            const currentField = fields[field]
            if (currentField.errors !== undefined) {
                const error = {
                    message: currentField.errors.toString(),
                    field: field
                }
                errorObj.errors.push(error)
            }
        }
    }
}

export const tokenToExpireinMS = () => {
    const timeNow = new Date(Date.now()).toUTCString().toString()
    const timeNowMs = Date.parse(timeNow)
    const expirationTimeMs = Date.parse(localStorage.getItem('janrainCaptureToken_Expires'))
    return expirationTimeMs - timeNowMs
}

export default function JanrainForm({ children , needActiveSession = false}) {

    const [janrainReady, setJanrainReady] = useState(false);
    const [screen, setScreen] = useState(undefined);

    // state mgmt
    let screenToRender = screen ? screen : 'signIn';
    const [globalState, setGlobalState] = useJanrainGlobalState();

    useIsomorphicLayoutEffect(() => {
        initJanrainScript();
        janrainInit(() => {
            window.janrain.capture.ui.start();
            handleFormError();
            window.janrain.events.onCaptureScreenShow.addHandler((data) => {
                setScreen(data.screen);
            })
            if(needActiveSession) {
                // validate if session is active, if not we redirect to login page
                window.janrain.events.onCaptureSessionNotFound.addHandler(() => {
                    redirectTo(process.env.JANRAIN_LOGIN_PATH);
                })
                validateCustomSessionTimeoutActive();
            }
            setJanrainReady(true);
        });
    }, []);

    function handleFormError() {
        window.janrain.events.onCaptureSaveFailed.addHandler((response) => {
            if(response.status === 'error'){

                window.janrain.events.onCaptureRenderStart.addHandler((flow) => {
                    const errorObj = collectFormErrors(flow, response)
                    if(errorObj !== undefined) onFormError(errorObj)
                })
            }
        })

        const onFormError = (result) => {
            setGlobalState({ formErrorResponse: result });
            setGlobalState({isInvalidForm: !!result.form});
        }
        window.janrain.events.onCaptureLoginFailed.addHandler(onFormError);
        window.janrain.events.onCaptureRegistrationFailed.addHandler(onFormError);
    }

    function initJanrainScript() {
        // Check for settings. If there are none, create them
        if (typeof window.janrain !== 'object') window.janrain = {}
        if (typeof window.janrain.settings !== 'object') window.janrain.settings = {}
        if (typeof window.janrain.settings.capture !== 'object') window.janrain.settings.capture = {}

        // Load Engage and Capture. 'login' is Engage, 'capture' is Capture.
        // Changing these values without guidance can result in unexpected behavior.
        window.janrain.settings.packages = ['login', 'capture']

        // --- Required Registration (Capture) Settings ----------------------------
        window.janrain.settings.appUrl = process.env.JANRAIN_APP_URL;
        var httpLoadUrl = process.env.JANRAIN_HTTP_LOAD_URL;
        var httpsLoadUrl = process.env.JANRAIN_HTTPS_LOAD_URL;

        // dev
        window.janrain.settings.capture.captureServer = process.env.JANRAIN_CAPTURE_SERVER;
        window.janrain.settings.capture.appId = process.env.JANRAIN_CAPTURE_APP_ID;
        window.janrain.settings.capture.clientId = process.env.JANRAIN_CAPTURE_CIENT_ID;
        window.janrain.settings.capture.flowName = process.env.JANRAIN_CAPTURE_FLOW_NAME;
        window.janrain.settings.capture.flowVersion = process.env.JANRAIN_CAPTURE_FLOW_VERSION;
        window.janrain.settings.capture.locale = process.env.JANRAIN_CAPTURE_LOCALE;

        // --- Registration (Capture) Widget Settings ------------------------------
        window.janrain.settings.capture.redirectUri = process.env.JANRAIN_CAPTURE_REDIRECT_URI;
        window.janrain.settings.capture.registerFlow = 'socialRegistration'
        window.janrain.settings.capture.setProfileCookie = true
        window.janrain.settings.capture.keepProfileCookieAfterLogout = false
        window.janrain.settings.capture.noModalBorderInlineCss = true
        window.janrain.settings.capture.responseType = process.env.JANRAIN_CAPTURE_RESPONSE_TYPE;
        window.janrain.settings.capture.returnExperienceUserData = []
        window.janrain.settings.capture.stylesheets = []
        window.janrain.settings.capture.mobileStylesheets = []
        window.janrain.settings.capture.noStyling = true

        // --- reCAPTCHA Version ---------------------------------------------------
        /*--
            This setting specifies which version of reCAPTCHA you wish to use.
            If left unspecified the application will default to version 1. If the
            recaptchaInvisible = true then recaptchaVersion = 2
                                                                                --*/
        window.janrain.settings.capture.recaptchaVersion = 2
        window.janrain.settings.capture.recaptchaInvisible = true

        // --- Social Login (Engage) Widget Settings -------------------------------
        window.janrain.settings.language = process.env.JANRAIN_SETTING_LANGUAGE;
        if (document.location.protocol === 'https:') {
            window.janrain.settings.tokenUrl = process.env.JANRAIN_SETTING_TOKEN_URL_HTTP;
        } else {
            window.janrain.settings.tokenUrl = process.env.JANRAIN_SETTING_TOKEN_URL_HTTPS;
        }
        window.janrain.settings.tokenAction = process.env.JANRAIN_SETTING_TOKEN_ACTION;
        window.janrain.settings.showAttribution = false;
        window.janrain.settings.borderColor = '#ffffff';
        window.janrain.settings.fontFamily = 'Helvetica, Lucida Grande, Verdana, sans-serif';
        window.janrain.settings.width = 300;
        window.janrain.settings.actionText = ' ';

        // --- BEGIN WIDGET INJECTION CODE -----------------------------------------
        /********* WARNING: *******************************************************\
         |      DO NOT EDIT THIS SECTION                                            |
         | This code injects the Capture Widget. Modifying this code can cause the  |
         | Widget to load incorrectly or not at all.                                |
         \**************************************************************************/

        function isReady() {
            window.janrain.ready = true
        }
        if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', isReady, false);
        } else {
            window.addEventListener('onload', isReady)
        }

        var injector = document.createElement('script')
        injector.type = 'text/javascript'
        injector.id = 'janrainAuthWidget'
        if (document.location.protocol === 'https:') {
            injector.src = httpsLoadUrl
        } else {
            injector.src = httpLoadUrl
        }
        var firstScript = document.getElementsByTagName('script')[0]
        if (firstScript) {
            firstScript.parentNode.insertBefore(injector, firstScript)
        }

        // --- END WIDGET INJECTION CODE -------------------------------------------
        function janrainCaptureWidgetOnLoad() {
            window.janrain.settings.capture.screenToRender = ''
        }
    }

    return <>
        {janrainReady &&
            <>
                {children}
            </>
        }
        <FakeJanrainScreen id={screenToRender}/>
    </>
}
