import React, { useContext, useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Transition } from 'react-transition-group';
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';

import {
    registrationHeader,
    registrationUrl,
    formatUSRegistrationBody,
    formatEURegistrationBody,
    sendMessageUrl,
    sendHeader,
    formatMessageBody,
    formatConstestMessageBody,
    validateUserUrl,
    validateUserHeader,
    getValidateUserBody,
    campaignUrl,
    campaignHeader,
    surveyUrl,
    submitionSurveyUrl,
    surveyHeader,
    getFormattedLocale,
    formatWaitlistSurveyBody
} from '../../adapters/model/service/gcs/gcsService';

import { checkPattern, customJsonstringify } from '../../adapters/helpers/Utils';

import Heading from '../Heading/Heading'
import Image from '../Image/Image';
import Icon from '../Icon/Icon';
import {SpotlightWaitlistConstants} from '../../adapters/helpers/Constants';


import Label from '../Label/Label';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import ObLink from '../ObLink/ObLink'



function SpotlightWaitlist(props) {
    const { document: entity, isMedium, index } = props;

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const block = new Block(entity);

    const emailExceedLimitLabel = block.getFieldValue(SpotlightWaitlistConstants.emailLimitExceedLabel)?.fields?.text;
    const invalidLocaleLabel = block.getFieldValue(SpotlightWaitlistConstants.invalidLocaleLabel)?.fields?.text;
    const serviceUnavailableLabel = block.getFieldValue(SpotlightWaitlistConstants.serviceUnavailableLabel)?.fields?.text;
    const countrySelectLabel = block.getFieldValue(SpotlightWaitlistConstants.countrySelectLabel);
    const countrySelectList = block.getFieldValue(SpotlightWaitlistConstants.countrySelectList);
    const countrySelectListDefaultOption = block.getFieldValue(SpotlightWaitlistConstants.countrySelectListDefaultOption);
    const countrySelectErrorMessage = block.getFieldValue(SpotlightWaitlistConstants.countrySelectErrorMessage);
    const countrySelectMention = block.getFieldValue(SpotlightWaitlistConstants.countrySelectMention);
    const addressTypeCode = block.getFieldValue(SpotlightWaitlistConstants.addressTypeCode);

    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isThankYouGermany, setIsThankYouGermany] = useState(false);
    const [countryList, setCountryList] = useState({});
    const [optInId, setOptInId] = useState(false);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(false);
    const [surveyId, setSurveyId] = useState(false);
    const [sourceQuestionId, setSourceQuestionId] = useState(false);

    const modalRef = useRef(null);
    const blockRef = useRef(null);
    const firstLegalModalRef = useRef(null);
    const customStyles = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'relative',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : 'auto',
            bottom              : 'auto',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(255, 255, 255, 0.5)'
        }
    };

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'fixed',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : '0',
            bottom              : '0',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(255, 255, 255, 0.5)'
        }
    };

    useEffect(() => {
        const spotlightThankYouGermanySelector = document.getElementsByClassName('thank-you-de');

        // Thank you page
        if(spotlightThankYouGermanySelector.length > 0) {
            setIsThankYouGermany(true);
            // the state change will trigger the post with the current opt in id
            setOptinId();
        } else {
            if (document.querySelector('.js-modal-container')) {
                Modal.setAppElement('.js-modal-container');
            }

            // fetch the campaign data and create the country list from it, and from contentful countries.
            if(countrySelectList) {
                populateCountryListFromCampaignData();
            } else {
                // case for US
                setOptinId()
            }

            if(surveyUrl) {
                setQuestionsIds();
            }
        }

    },[]);

    useEffect(() => {
        const spotlightThankYouGermanySelector = document.getElementsByClassName('thank-you-de');

        // Post user validation to mind tree
        if(spotlightThankYouGermanySelector.length > 0 && optInId && validateUserUrl) {
            postDoubleOptInParameters(optInId)
        }
    }, [optInId]);

    useEffect(() => {
        const errors = document.querySelector('.ob-focus-error');
        if (!errors) {
            return;
        }
        errors.focus();
    },[erroneousInputs, serverSideErrorMessage]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsSubmitting(true);
        const form = event.target;
        const termsAndConditionsChecked = document.querySelector('#TermsAndConditions') != null && document.querySelector('#TermsAndConditions').checked;
        setHasAcceptedTermsAndConditions(termsAndConditionsChecked);
        const inputs = form.querySelectorAll('input, select');
        let errors = [];

        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({id: inputs[i].getAttribute('id')});
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                }
            }
        }

        if (errors.length === 0) {
            const country = getCountryById(form.spotlight_country?.value);
            const requestBody = (process.env.MARKET == 'en-us')
                ? formatUSRegistrationBody(form.firstname?.value, form.email?.value, country.countryId, optInId)
                : formatEURegistrationBody(form.firstname?.value,
                        form.email?.value,
                        country?.locale,
                        addressTypeCode,
                        country.countryId,
                        country.countryIsoCode,
                        optInId,
                        termsAndConditionsChecked);

            fetch(registrationUrl, {
                method: 'POST',
                headers: registrationHeader,
                body: customJsonstringify(requestBody)
            })
                .then(async(response) => {
                    // 201 for created
                    if (response.status === 201 || response.status === 200) {

                        setErroneousInputs([]);
                        setIsModalOpen(true);

                        if (typeof (window.dataLayer) !== 'undefined') {
                            window.dataLayer.push({
                                'event': 'customEvent',
                                'GAeventCategory': 'event_crm_action',
                                'GAeventAction': 'event_profile_email_signup'
                            });
                        }

                        // convert response
                        const registrationResponse = await response.json();
                        // save in memory the order id
                        const orderId = registrationResponse?.orderId;
                        await postSurveyInformations(orderId);

                        if (process.env.MARKET == 'en-us' || process.env.MARKET == 'sv-se') {
                            const messageRequestBody = (process.env.MARKET == 'en-us')
                                ? formatMessageBody(form.email?.value)
                                : formatConstestMessageBody(undefined, form.email?.value, getFormattedLocale());
                            if(sendMessageUrl){
                                fetch(sendMessageUrl, {
                                    method: 'POST',
                                    headers: sendHeader,
                                    body: customJsonstringify(messageRequestBody)
                                }).catch(() => {
                                    setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
                                });
                            }
                        } else if (process.env.MARKET == 'es-es') {
                            try {
                                /* eslint-disable */
                                if (versaTagObj) {
                                    versaTagObj.generateRequest('https://io.oralb.es/es-es/Inscribe-TYP.html');
                                }
                                /* eslint-enable */
                            } catch(e) {
                                console.info('versaTag is not defined');
                            }
                        }

                    } else {
                        return response.json();
                    }
                    setIsSubmitting(false);
                })
                .then(object => {
                    // print server side error message
                    if(object && object.error && object.error.message) {
                        if ((object.error.code === 'EXCEED_EMAIL_LIMIT') && emailExceedLimitLabel) {
                            setServerSideErrorMessage(emailExceedLimitLabel);
                        } else if ((object.error.code === 'INVALID_LOCALE') && invalidLocaleLabel) {
                            setServerSideErrorMessage(invalidLocaleLabel);
                        } else {
                            setServerSideErrorMessage(object.error.message);
                        }
                    } else {
                        setServerSideErrorMessage(false);
                    }
                    setIsSubmitting(false);
                })
                .catch(() => {
                    setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
                    setIsSubmitting(false);
                });
        } else {
            setErroneousInputs([...errors]);
            setIsSubmitting(false);
        }
    };

    const postDoubleOptInParameters = (optInId) => {
        const requestBody = getValidateUserBody(optInId);

        fetch(validateUserUrl, {
            method: 'POST',
            headers: validateUserHeader,
            body: customJsonstringify(requestBody)
        }).catch((error) => {
            console.info(error);
        });
    };

    const setOptinId = function() {
        let optInId = '';
        fetch(campaignUrl, {
            method: 'GET',
            headers: campaignHeader,
        })
        .then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    let optIns = json.optIns[0];
                    // set optIn id that will be use in the form
                    if (optIns) {
                        setOptInId(optIns.optInId);
                    }
                })
            }
        })

        return optInId;
    };

    const postSurveyInformations = async(orderId) => {
        const requestBody = formatWaitlistSurveyBody(getFormattedLocale(),
            surveyId,
            orderId,
            sourceQuestionId);

        if(submitionSurveyUrl) {
            fetch(submitionSurveyUrl, {
                method: 'POST',
                headers: surveyHeader,
                body: customJsonstringify(requestBody)
            }).catch(() => {
                setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
                setIsSubmitting(false);
            });
        }
    };

    const setQuestionsIds = () => {
        fetch(surveyUrl, {
            method: 'GET',
            headers: surveyHeader,
        }).then(async(surveyResponse) => {
            return surveyResponse.json();
        }).then((surveys) => {
            surveys.forEach(survey => {

                setSurveyId(survey.surveyId);

                survey?.questions.forEach(question => {
                    const questionName = question?.questionName;
                    if(questionName && questionName.toLowerCase().indexOf('source') > -1) {
                        setSourceQuestionId(question.questionId);
                    }
                })
            })
        });
    };

    const populateCountryListFromCampaignData = () => {
        fetch(campaignUrl, {
            method: 'GET',
            headers: campaignHeader,
        }).then((response) => {
                if (response.status === 200) {
                    response.json().then(json => {
                        let optIns = json.optIns[0];
                        // set optIn id that will be use in the form
                        if (optIns) {
                            setOptInId(optIns.optInId);
                        }
                        let filteredCountryListResult = [];
                        let campaignCountryList = json.campaignCountriesForAddress;
                        // compare countries from the campaign with the one in contentful
                        // only add the countries present in the campgain in the list for the dropdown
                        for (let i = 0; i < countrySelectList.length; ++i) {
                            for (let y = 0; y < campaignCountryList.length; ++y) {
                                let contentfulCountryCode = countrySelectList[i]?.fields?.countryCode;
                                let campaignCountryCode = campaignCountryList[y]?.countryCode;
                                let campaignCountryId = campaignCountryList[y]?.countryId;
                                if (contentfulCountryCode && campaignCountryCode
                                        && (contentfulCountryCode.toUpperCase() === campaignCountryCode.toUpperCase())) {

                                    const isoCode = campaignCountryList[y]?.isoCode.length > 2 ? campaignCountryList[y]?.isoCode : campaignCountryList[y]?.countryCode;

                                    let countryObject = {
                                        label: countrySelectList[i]?.fields?.label?.fields?.text,
                                        countryIsoCode: isoCode,
                                        countryId: campaignCountryId,
                                        locale: countrySelectList[i]?.fields?.locale
                                    };
                                    filteredCountryListResult.push(countryObject);
                                }
                            }
                        }
                        // set countryList for dropdown
                        setCountryList(filteredCountryListResult);
                    })
                } else {
                    setServerSideErrorMessage(serviceUnavailableLabel);
                    setIsSubmitting(false);
                }
            })
            .catch(() => {
                setServerSideErrorMessage(serviceUnavailableLabel);
                setIsSubmitting(false);
            });
    };

    const getCountryById = (id) => {
        let country = {};
        let countryListData = countryList;

        for (let i = 0; i < countryListData.length; ++i) {
            let countryId = countryListData[i]?.countryId;
            if (countryId === parseInt(id)) {
                country = countryListData[i];
            }
        }

        return country;
    };

    const title = block.getFieldValue(SpotlightWaitlistConstants.title);
    const body = block.getFieldValue(SpotlightWaitlistConstants.body);
    const ctaLabel = block.getFieldValue(SpotlightWaitlistConstants.callToActionLabel);
    const legalText = block.getFieldValue(SpotlightWaitlistConstants.legalText);
    const formLabels = block.getFieldValue(SpotlightWaitlistConstants.formLabels);
    const classNames = block.getFieldValue(SpotlightWaitlistConstants.classNames);
    const anchorId = block.getFieldValue(SpotlightWaitlistConstants.anchorId);
    const backgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.backgroundAsset);
    const mobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAsset);
    const thankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouTitleLabel)?.fields?.text;
    const thankYouBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.thankYouBackgroundAsset);
    const thankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouDescriptionLabel)?.fields?.text;
    const closeModalLabel = block.getFieldValue(SpotlightWaitlistConstants.closeModalLabel)?.fields?.text;
    const thankYouCallToAction = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToAction);
    const thankYouCallToActionLink = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionLink);
    const thankYouCallToActionIconName = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionIconName);
    const numberOfErrorsLabel = block.getFieldValue(SpotlightWaitlistConstants.numberOfErrorsLabel);
    const modalLabelFirst = block.getFieldValue(SpotlightWaitlistConstants.modalLabelFirst);
    const modalLabelSecond = block.getFieldValue(SpotlightWaitlistConstants.modalLabelSecond);
    const alternateThankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouTitleLabel)?.fields?.text;
    const alternateThankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouDescriptionLabel)?.fields?.text;
    const duration = 600;
    const callToPreOrderActionLabel = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLabel);
    const callToPreOrderActionLink = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLink);

    const closeFirstLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const setLegalModals = () => {
        if(blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    if(isActiveBlock) {
        setLegalModals();
    }

    return (
        <Transition in={index === undefined} timeout={duration}>
            {() => (
                <div
                    ref={blockRef}
                    className={`ob-spotlightContentBlock ob-spotlight-waitlist js-modal-container ${classNames}`}
                    id={anchorId}
                    data-isactiveblock={isActiveBlock} >
                    { (isMedium && !isThankYouGermany) &&
                    <div className={'ob-spotlight-waitlist__mobile-img'}>
                        <Image image={mobileBackgroundAsset ? mobileBackgroundAsset : undefined} />
                    </div>
                    }
                    <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : (isThankYouGermany ? mobileBackgroundAsset : undefined)} customStyles={{backgroundSize: 'cover', backgroundPositionY: '-15px', backgroundRepeat: 'no-repeat'}}>
                        <div className={'ob-spotlight-waitlist__wrapper'}>
                            <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                            <div className={'ob-spotlight-waitlist__body'}>{body}</div>

                            {(isThankYouGermany && (thankYouCallToAction && thankYouCallToActionLink)) &&
                                <ObLink href={thankYouCallToActionLink} className="white ob-spotlight-waitlist__link-de" icon="chevronRight" roundedIcon="blue">
                                    <Icon name="chevronRight" roundedIcon="blue" />
                                    {thankYouCallToAction}
                                </ObLink>
                            }

                            {   serverSideErrorMessage &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                {serverSideErrorMessage}
                            </div>
                            }

                            { erroneousInputs.length > 0 &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                <Label label={numberOfErrorsLabel} tokens={{numErrors: erroneousInputs.length, plural: erroneousInputs.length > 1 ? 's': ''}} />
                            </div>
                            }

                            { (isActiveBlock && formLabels) &&
                                <form className={'ob-spotlight-waitlist__form'} onSubmit={handleSubmit} noValidate>

                                    <ul className={'ob-spotlight-waitlist__form-list'}>
                                        { (countrySelectLabel && countryList && countryList.length > 0) &&
                                        <li className={'ob-spotlight-waitlist__form-list-item'}>
                                            <label htmlFor={'spotlight_country'} className={'ob-spotlight-waitlist__form-label'}>{countrySelectLabel}</label>

                                            <div className={'ob-spotlight-waitlist__form-select-container'}>
                                                <select
                                                    required={true}
                                                    id={'spotlight_country'}
                                                    name={'spotlight_country'}
                                                    className={'ob-spotlight-waitlist__form-input --select'}>
                                                    { countrySelectListDefaultOption &&
                                                    <option value="" defaultValue>{countrySelectListDefaultOption}</option>
                                                    }

                                                    {
                                                        countryList.map((selectOption, selectOptionIndex) => (
                                                            <option value={selectOption.countryId} key={'selectOption-' + selectOptionIndex}>{selectOption.label}</option>
                                                        ))
                                                    }
                                                </select>

                                                <Icon name={SpotlightWaitlistConstants.chevronDown} size='1.8' />
                                            </div>

                                            { countrySelectMention &&
                                            <p className={'ob-spotlight-waitlist__form-mention'}>
                                                {countrySelectMention}
                                            </p>
                                            }

                                            { erroneousInputs.length > 0 &&
                                            erroneousInputs.map((error, index) => {
                                                return error.id === 'country' && countrySelectErrorMessage &&
                                                    <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                        {countrySelectErrorMessage}
                                                    </p>
                                            })
                                            }
                                        </li>
                                        }

                                        {
                                            formLabels.map((formLabel, formLabelIndex) => (
                                                <li className={'ob-spotlight-waitlist__form-list-item'} key={'formLabel-' + formLabelIndex}>
                                                    <label htmlFor={formLabel.fields.id} className={'ob-spotlight-waitlist__form-label'}>{formLabel.fields.label.fields.text}</label>

                                                    {formLabel.fields.contentType === 'formSelect' ? (
                                                        <div className={'ob-spotlight-waitlist__form-select-container'}>
                                                            <select
                                                                required={formLabel?.fields?.validation?.fields?.required}
                                                                id={formLabel.fields.id}
                                                                name={formLabel.fields.id}
                                                                className={'ob-spotlight-waitlist__form-input --select'}>
                                                                {formLabel.fields.defaultValue &&
                                                                <option value="" defaultValue>{formLabel.fields.defaultValue}</option>
                                                                }

                                                                {
                                                                    formLabel.fields.options.map((selectOption, selectOptionIndex) => (
                                                                        <option value={selectOption.fields.value} key={'selectOption-' + selectOptionIndex}>{selectOption.fields.label}</option>
                                                                    ))
                                                                }
                                                            </select>

                                                            <Icon name={SpotlightWaitlistConstants.chevronDown} size='1.8' />
                                                        </div>
                                                    ) :(
                                                        <input
                                                            type={formLabel.fields.type}
                                                            pattern={formLabel?.fields?.validation?.fields?.pattern}
                                                            required={formLabel?.fields?.validation?.fields?.required}
                                                            autoComplete={formLabel?.fields?.label?.fields?.autoCompleteText}
                                                            id={formLabel.fields.id}
                                                            name={formLabel.fields.id}
                                                            className={'ob-spotlight-waitlist__form-input'} />
                                                    )}

                                                    {formLabel.fields.fieldMention &&
                                                    <p className={'ob-spotlight-waitlist__form-mention'}>
                                                        {formLabel.fields.fieldMention}
                                                    </p>
                                                    }

                                                    { erroneousInputs.length > 0 &&
                                                    erroneousInputs.map((error, index) => {
                                                        return error.id === formLabel.fields.id && formLabel?.fields?.validation?.fields?.errorMessage &&
                                                            <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                                {formLabel.fields.validation.fields.errorMessage}
                                                            </p>
                                                    })
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    { (process.env.MARKET != 'en-us') &&
                                        <div className={'ob-spotlight-waitlist__legal-text ob-spotlight-waitlist__legal-text-not-US'}
                                        dangerouslySetInnerHTML={{__html: legalText}}></div>
                                    }
                                    <button
                                        type={SpotlightWaitlistConstants.submit}
                                        className={`ob-button event_profile_email_signup${process.env.MARKET != 'en-us' ? ' not-us' : ''}`}
                                        data-action-detail={`${ctaLabel.fields.text} submit`}
                                        disabled={isSubmitting}>
                                        {ctaLabel.fields.text}
                                        { isSubmitting &&
                                        <span className={'ob-button__spinner'}></span>
                                        }
                                    </button>
                                </form>
                            }

                            { callToPreOrderActionLink && callToPreOrderActionLabel &&
                                <ObLink  isExternalLink={true} href={callToPreOrderActionLink} className="ob-button btn-preorder not-us ">
                                    <span className="span-preorder">{`${callToPreOrderActionLabel}`}</span>
                                </ObLink>
                            }

                            { (process.env.MARKET == 'en-us') &&
                                <div className={'ob-spotlight-waitlist__legal-text'} dangerouslySetInnerHTML={{__html: legalText}}></div>
                            }

                        </div>
                    </Image>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        closeTimeoutMS={250}
                        style={customStyles}
                        ref={modalRef}
                        className={'ob-thank-you-modal'}
                    >
                        <div className={'ob-spotlight-waitlist__modal-container'}>
                            {!isMedium &&
                                <div className={'ob-spotlight-waitlist__modal-content'}>
                                    <Image image={thankYouBackgroundAsset} />
                                </div>
                            }
                            <div className={'ob-spotlight-waitlist__modal-text-container ob-spotlight-waitlist__modal-content'}>

                            {(!hasAcceptedTermsAndConditions && process.env.MARKET == 'de-de') ? (
                                    <div>
                                        <Heading>{alternateThankYouTitleLabel}</Heading>
                                        <p className={'ob-spotlight-waitlist__modal-text-content'}>{alternateThankYouDescriptionLabel}</p>
                                    </div>
                                ) : (
                                    <div>
                                        <Heading>{thankYouTitleLabel}</Heading>
                                        <p className={'ob-spotlight-waitlist__modal-text-content'}>{thankYouDescriptionLabel}</p>
                                    </div>
                                )
                            }

                            {(thankYouCallToAction && thankYouCallToActionLink) &&
                                <p className={'ob-spotlight-waitlist__modal-cta-content'}>
                                    <a className={'ob-spotlight-waitlist__modal-cta'}
                                        href={thankYouCallToActionLink}>
                                        {thankYouCallToActionIconName &&
                                        <Icon name={thankYouCallToActionIconName}
                                            size={'1.4'}></Icon>
                                        }
                                        {thankYouCallToAction}
                                    </a>
                                </p>
                            }


                            </div>
                            {isMedium &&
                            <div>
                                <Image image={thankYouBackgroundAsset} />
                            </div>
                            }
                            <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                                <Icon name={SpotlightWaitlistConstants.close} size='2.4' />
                            </button>
                        </div>
                    </Modal>

                    {(modalLabelFirst || modalLabelSecond) &&
                        <Modal
                            isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                            style={customStylesLegal}
                            onRequestClose={closeFirstLegalModal}
                            closeTimeoutMS={250}
                            overlayClassName={'event_button_click'}
                            ref={firstLegalModalRef}
                        >
                            <div className='ob-spotlight-waitlist__modal-container-legal'>

                                { isFirstLegalModalOpen && modalLabelFirst &&
                                <p dangerouslySetInnerHTML={{__html: modalLabelFirst}}></p>
                                }

                                { isSecondLegalModalOpen && modalLabelSecond &&
                                <p dangerouslySetInnerHTML={{__html: modalLabelSecond}}></p>
                                }

                                <button className='event_close_window ob-modal-close'
                                    onClick={closeFirstLegalModal}
                                    aria-label={closeModalLabel} type='button'>
                                    <Icon name='close' size='2.4' color='#000' />
                                </button>

                            </div>
                        </Modal>
                    }
                </div>
            )}
        </Transition>
    )
}

export default mediaQueryHOC(SpotlightWaitlist);
// This export is for unit testing (do not remove) :
export const SpotlightWaitlistTest = SpotlightWaitlist;

SpotlightWaitlist.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};
