import throttle from 'lodash/throttle';
import React, {useCallback, useMemo, useRef, useState, useEffect} from 'react';

import {uniqBy} from '../../adapters/helpers/Array';
import {useIsomorphicLayoutEffect} from '../../adapters/helpers/Hooks';

import DotNav from '../DotNav/DotNav';
import Label from '../Label/Label';
import PropTypes from 'prop-types';
import BrushConfiguratorProduct from './BrushConfiguratorProduct';

import {BrushConfiguratorConstants} from '../../adapters/helpers/Constants';

import { scrollIntoView } from '../../adapters/helpers/Scroll';


export default function BrushConfigurator(props) {
    const {document: doc} = props;

    const collection = props.extraAttributes.entity;
    const seriesArray = collection.series;

    const scrollRef = useRef();

    const [selectedNeedsMap, setSelectedNeedsMap] = useState({});
    const [selectedFeaturesMap, setSelectedFeaturesMap] = useState({});
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

    const titleLabel = doc.fields.title;
    const oralCareNeedsLabel = doc.fields.oralCareNeedsLabel;
    const selectFeaturesLabel = doc.fields.selectFeaturesLabel;
    const matchLabel = doc.fields.matchLabel;
    const matchDelimiterLabel = doc.fields.matchDelimiterLabel;
    const anchorId = doc.fields.anchorId;
    const classNames = doc?.fields?.classNames || '';

    const allProducts = useMemo(
        () => {
            const productArrays = seriesArray ? seriesArray.map(s => s.fields.products) : [];
            const products = [].concat(...productArrays);

            return uniqBy(products, p => p.fields.uniqueId);
        },
        [seriesArray]
    );

    const allNeeds = doc?.fields?.needs;
    const allFeatures = doc?.fields?.features;

    const selectedNeeds = useMemo(
        () => allNeeds.filter(need => selectedNeedsMap[need.fields.name]),
        [allNeeds, selectedNeedsMap]
    );

    useEffect(() => {
        if (scrollRef?.current) {
            scrollIntoView(scrollRef.current, {behavior: BrushConfiguratorConstants.smooth})
        }
    }, [selectedFeaturesMap, selectedNeedsMap]);

    const matchingProducts = useMemo(
        () => {
            const selectedNeedKeys = Object.keys(selectedNeedsMap);
            const selectedFeatureKeys = Object.keys(selectedFeaturesMap);

            if (selectedNeedKeys.length === 0 && selectedFeatureKeys.length === 0) {
                // no criteria selected, so there are no matching products to display
                return [];
            }

            // find all products that have every selected need and feature

            return allProducts.filter(product => {
                for (let i = 0; i < selectedNeedKeys.length; ++i) {
                    const selectedKey = selectedNeedKeys[i];

                    if (!product?.fields?.needs) {
                        return false;
                    }

                    if (!product?.fields?.needs.some(f => f.fields.name === selectedKey)) {
                        return false;
                    }
                }

                for (let i = 0; i < selectedFeatureKeys.length; ++i) {
                    const selectedKey = selectedFeatureKeys[i];

                    if (!product.fields.collectionFeatures) {
                        return false;
                    }

                    if (!product.fields.collectionFeatures.some(f => f.fields.name === selectedKey)) {
                        return false;
                    }
                }

                return true;
            });
        },
        [allProducts, selectedNeedsMap, selectedFeaturesMap]
    );

    const handleNeedToggle = useCallback(
        (event) => {
            const key = event.currentTarget.getAttribute(BrushConfiguratorConstants.dataName);

            setSelectedNeedsMap(prevSelections => toggleSelection(prevSelections, key));

            if (scrollRef.current) {
                scrollRef.current.scrollLeft = 0;
            }
        },
        []
    );

    const handleFeatureToggle = useCallback(
        (event) => {
            const key = event.currentTarget.getAttribute(BrushConfiguratorConstants.dataName);

            setSelectedFeaturesMap(prevSelections => toggleSelection(prevSelections, key));

            if (scrollRef.current) {
                scrollRef.current.scrollLeft = 0;
            }
        },
        []
    );

    const updateCurrentMatchIndex = useCallback(
        () => {
            if (scrollRef.current) {
                setCurrentMatchIndex(findCentermostChildNodeIndex(scrollRef.current));
            }
        },
        []
    );

    const handleScroll = useMemo(
        () => throttle(updateCurrentMatchIndex, 100),
        [updateCurrentMatchIndex]
    );

    const handleMatchNavClick = useCallback(
        (index) => {
            const items = scrollRef.current?.querySelectorAll('.ob-brush-configurator__matches-item');

            // Workaround Chrome bug where the horizontal scroll is aborted if
            // the vertical scroll is already at the target position
            window.scrollTo(window.scrollX, Math.round(window.scrollY) + 1);

            items?.[index]?.scrollIntoView({
                behavior: BrushConfiguratorConstants.smooth,
                block: BrushConfiguratorConstants.center,
                inline: BrushConfiguratorConstants.center
            });
        },
        []
    );

    const toggleSelection = (prevSelections, key) => {
        const selections = {...prevSelections};

        if (selections[key]) {
            delete selections[key];
        } else {
            selections[key] = true;
        }

        return selections;
    };

    const findCentermostChildNodeIndex = (parentNode) => {
        const itemNodes = parentNode.childNodes;
        const originRect = parentNode.getBoundingClientRect();
        const originLeft = originRect.width * 0.5 + originRect.left;

        let currentIndex = 0;
        let currentDistance = Infinity;

        // find item closest to center of container
        for (let i = 0; i < itemNodes.length; ++i) {
            const itemRect = itemNodes[i].getBoundingClientRect();
            const itemLeft = itemRect.width * 0.5 + itemRect.left;
            const itemDistance = Math.abs(itemLeft - originLeft);

            if (itemDistance < currentDistance) {
                currentIndex = i;
                currentDistance = itemDistance;
            }
        }

        return currentIndex;
    };

    useIsomorphicLayoutEffect(
        () => {
            updateCurrentMatchIndex();

            return () => {
                handleScroll.cancel();
            };
        },
        [updateCurrentMatchIndex, handleScroll]
    );

    return (
        <div className={`ob-brush-configurator ${classNames}`} id={anchorId}>
            {!!titleLabel && (
                <div className="ob-brush-configurator__top">
                    <h2 className="ob-brush-configurator__title">
                        {titleLabel.fields.text}
                    </h2>
                </div>
            )}

            <div className="ob-brush-configurator__separator"/>

            <div className="ob-brush-configurator__needs">
                {!!oralCareNeedsLabel && (
                    <div className="ob-brush-configurator__needs-top">
                        <h2 className="ob-brush-configurator__subtitle">
                            {oralCareNeedsLabel.fields.text}
                        </h2>
                    </div>
                )}

                <ul className="ob-brush-configurator__needs-list">
                    {!!allNeeds && allNeeds.map((need, i) => {
                        const active = selectedNeedsMap[need.fields.name];

                        return (
                            <li className="ob-brush-configurator__needs-item" key={i}>
                                <button
                                    className="event_button_click ob-brush-configurator__toggle ob-outline-button"
                                    data-action-detail={need.fields.name}
                                    type="button"
                                    role={BrushConfiguratorConstants.switch}
                                    data-name={need.fields.name}
                                    aria-checked={active ? 'true' : 'false'}
                                    onClick={handleNeedToggle}
                                >
                                    {need.fields.name}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="ob-brush-configurator__features">
                {!!selectFeaturesLabel && (
                    <div className="ob-brush-configurator__features-top">
                        <h2 className="ob-brush-configurator__subtitle">
                            {selectFeaturesLabel.fields.text}
                        </h2>
                    </div>
                )}

                <ul className="ob-brush-configurator__features-list">
                    {!!allFeatures && allFeatures.map((feature, i) => {
                        const active = selectedFeaturesMap[feature.fields.name];

                        return (
                            <li className="ob-brush-configurator__features-item" key={i}>
                                <button
                                    className="event_button_click ob-brush-configurator__toggle ob-outline-button"
                                    data-action-detail={feature.fields.name}
                                    type="button"
                                    role={BrushConfiguratorConstants.switch}
                                    data-name={feature.fields.name}
                                    aria-checked={active ? 'true' : 'false'}
                                    onClick={handleFeatureToggle}
                                >
                                    <span className="ob-brush-configurator__toggle-inner">
                                        <span
                                            className={`ob-brush-configurator__toggle-icon ${feature.fields.logoId ? 'icon-' + feature.fields.logoId : ''}`}
                                            aria-hidden="true"/>

                                        <span className="ob-brush-configurator__toggle-text" dangerouslySetInnerHTML={{__html: feature.fields.title}}></span>
                                    </span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {(matchingProducts.length > 0) && (
                <div className="ob-brush-configurator__matches"  id={BrushConfiguratorConstants.features} >
                    {!!(matchLabel && matchDelimiterLabel && matchingProducts.length > 0) && (
                        <div className="ob-brush-configurator__matches-top">
                            <h2 className="ob-brush-configurator__subtitle" role={BrushConfiguratorConstants.status} aria-atomic={ matchingProducts.length > 0 ? true : false } aria-live={ matchDelimiterLabel && matchingProducts.length ? BrushConfiguratorConstants.live :BrushConfiguratorConstants.off }>
                                <Label label={matchLabel} tokens={{
                                    currentIndex: currentMatchIndex + 1,
                                    totalResults: matchingProducts.length
                                }}                                
                                />
                            </h2>
                        </div>
                    )}

                    <ul className="ob-brush-configurator__matches-list" ref={scrollRef} onScroll={handleScroll}>
                        {matchingProducts.map((product, i) => (
                            <li className="ob-brush-configurator__matches-item" key={product.uniqueId || i}>
                                <BrushConfiguratorProduct document={doc} product={product} needs={selectedNeeds}/>
                            </li>
                        ))}
                    </ul>

                    {(matchingProducts.length > 1) && (
                        <div className='ob-brush-configurator__matches-nav-ctn'>
                            <DotNav className="ob-brush-configurator__matches-nav"
                                count={matchingProducts.length}
                                current={currentMatchIndex}
                                color="blue"
                                onClick={handleMatchNavClick}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

BrushConfigurator.propTypes = {
    document: PropTypes.any,
    extraAttributes: PropTypes.any,
}




