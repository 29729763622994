import Block from '../../adapters/helpers/Block';
import React from 'react';
import { ContactUsBlockConstants } from '../../adapters/helpers/Constants'
import PropTypes from 'prop-types';



export default function ContactUsBlock(props) {
    
    const block = new Block(props);

    const className = `${ContactUsBlockConstants.contactUs} ${block.getFieldValue(ContactUsBlockConstants.classNames)}`;
    const anchorId = block.getAnchorId()
    const iframeTitle = props.document?.fields?.iframeTitle;

    return (
        <div className={`ob-contact-us ${className}`} id={anchorId} >
            <iframe 
            className={'ob-contact-us-iframe'} 
            title={iframeTitle} 
            src={process.env.CONTACTUS_IFRAME_URL}/>
        </div>
    )
}


ContactUsBlock.propTypes = {
    document: PropTypes.object
};