import React, {useEffect, useState} from 'react';
import Heading from '../Heading/Heading';
import Eyebrow from '../Eyebrow/Eyebrow';
import BodyText from '../BodyText/BodyText';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';
import { getSearchQueryParameter } from '../Janrain/Janrain/janrainForms/Janrain.helpers';
import {
    getJanrainCaptureProfileDataFromLocalStorage
} from '../Janrain/helpers/localstorage.helpers';
import {calculateHeaderHeight, isObjectEmpty} from '../../adapters/helpers/Utils';
import {redirectTo} from '../Janrain/helpers/redirect.helpers';

import Label from '../Label/Label';
import { enhanceProducts } from '../Janrain/helpers/product.helpers';
import {
    getConsumerProduct,
    postEmailNotification, postGWEProductWarrantyEmailNotification
} from '../../adapters/model/service/warrantyExtension/warrantyExtension.helpers';
import Modal from 'react-modal';
import {SubmitButton} from '../Janrain/components';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';

export default function CongratulationsBlock(props) {
    
    const [oralbProducts, setOralbProducts] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const title = props?.document?.fields?.title;
    const profileCreatedText = props?.document?.fields?.profileCreatedTextGwe;
    const proofOfPurchaseText = props?.document?.fields?.proofOfPurchaseText;
    const documentsText = props?.document?.fields?.documentsText;
    const productName = props?.document?.fields?.productNameLabelGwe;
    const typeCode = props?.document?.fields?.typeNumberLabelGwe;
    const dateOfPurchase = props?.document?.fields?.dateOfPurchase?.fields?.text;
    const dateOfRegistration = props?.document?.fields?.dateOfRegistration?.fields?.text;
    const warrantyExpirationDate = props?.document?.fields?.warrantyExpirationDate?.fields?.text;
    const pleaseNote = props?.document?.fields?.pleaseNote;
    const goToProfileLink = props?.document?.fields?.goToProfileLink;
    const clickHereText = props?.document?.fields?.clickHereText;
    const productCategoryList = props?.document?.fields?.registerProductBlock?.fields?.registerProductCategoryList;
    const warrantyExtensionTime = props?.document?.fields?.registerProductBlock?.fields?.warrantyExtensionTime || 1;
    const resendEmailLabel = props?.document?.fields?.resendEmailLabel?.fields?.text;
    const modalTitle = props?.document?.fields?.modalTitle?.fields?.text;
    const confirmLabel = props?.document?.fields?.confirmLabel?.fields?.text;
    const cancelLabel = props?.document?.fields?.cancelLabel?.fields?.text;
    const activateGWEWarrantyEmail = props?.document?.fields?.activateGWEWarrantyEmail || false;
    const termsAndConditionsText = props?.document?.fields?.termsAndCondition;
    const environmentalNoticeText = props?.document?.fields?.environmentalNoticeText;
    const environmentRecycleIcon = props?.document?.fields?.environmentRecycleIcon
    const mailSentConfirmationInfo = props?.document?.fields?.mailSentConfirmationInfo

    const closeModalTimeoutMS = 250;

    const customStyles = {
        overlay: {
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(0, 0, 0, 0.5)',
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center'
        },
        content: {
            position            : 'static',
            display             : 'flex'
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsSubmitting(false);
    };

    const openModal = event => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
        function onSuccess(gCSUserproductList) {
            const userProducts = enhanceProducts(gCSUserproductList);
            const productIdInUrl = getSearchQueryParameter('product_id') || '';
            if (!isObjectEmpty(userProducts)) {
                if (productIdInUrl !== '') {
                    const currentProducts = userProducts.filter(elem => (elem.id == productIdInUrl));
                    if (currentProducts) {
                        setOralbProducts(currentProducts);
                    } else {
                        // Product doesn't exist, redirect to profile
                        redirectTo(process.env.JANRAIN_PROFILE_PATH);
                    }
                } else {
                    setOralbProducts(userProducts);
                }
            } else {
                // no product to show, redirect to login
                redirectTo(process.env.JANRAIN_PROFILE_PATH);
            }
        }

        function onFailure() {
        }

        const userData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        getConsumerProduct(onSuccess, onFailure, userData, productCategoryList, warrantyExtensionTime);

    },[]);

    const printCongratsPage = () => {
        window.print();
        // Prevents the congrats Title to get cropped after print dialog is closed
        // Makes sure it's called after the calculateHeaderHeight() in ../MainMenu/MainMenu.js
        setTimeout(() => {
            calculateHeaderHeight();
        }, 100);
    };

    function resendNotificationEmail() {
        const userData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        if(userData) {
            setIsSubmitting(true);
            if(activateGWEWarrantyEmail) {
                postGWEProductWarrantyEmailNotification(userData, oralbProducts[0]?.id, closeModal);
            } else {
                postEmailNotification(userData, oralbProducts, closeModal);
            }
        }
    }

    return (
        <JanrainForm needActiveSession={true}>
            <div className={'ob-congrats js-modal-container'}>
                <Heading className={'ob-congrats__title'} tag={'h1'}>{title}</Heading>
                <p className={'ob-congrats__body-text'}>
                    <Label
                        label={profileCreatedText}
                        tokens={{warrantyExpirationDate: oralbProducts[0]?.warrantyExpirationDateInText}}
                    />
                </p>
                <button className={'ob-congrats__print-link'} type='button' onClick={printCongratsPage}>
                    <Eyebrow className={'ob-congrats__print-link-text'}>{proofOfPurchaseText}</Eyebrow>
                    <Icon name={'chevronRight'} className={'ob-congrats__print-icon'} />
                </button>
                <BodyText className={'ob-congrats__body-text'}>{documentsText}</BodyText>
                {oralbProducts?.map((oralbProduct, index) => {
                    return <ul className={'ob-congrats__product-info-list'} key={index}>
                        <li className={'ob-congrats__product-info-item'}>
                            {productName} <span className={'ob-bold'} dangerouslySetInnerHTML={{__html: (oralbProduct?.shortTitle) ? oralbProduct?.shortTitle : oralbProduct?.name}}></span>
                        </li>
                        <li className={'ob-congrats__product-info-item'}>
                            {typeCode} <span className={'ob-bold'}>{oralbProduct?.typeCode || ''}</span>
                        </li>
                        <li className={'ob-congrats__product-info-item'}>
                            {dateOfPurchase} <span className={'ob-bold'}>{oralbProduct?.traitDate}</span>
                        </li>
                        <li className={'ob-congrats__product-info-item'}>
                            {dateOfRegistration} <span className={'ob-bold'}>{oralbProduct?.registrationDate}</span>
                        </li>
                        <li className={'ob-congrats__product-info-item'}>
                            {warrantyExpirationDate} <span className={'ob-bold'}>{oralbProduct?.warrantyExpirationDate}</span>
                        </li>
                    </ul>
                }) }
                <Eyebrow className={'ob-congrats__disclaimer'}>{pleaseNote}</Eyebrow>

                <div className={'ob-congrats__questions'} dangerouslySetInnerHTML={{__html: clickHereText}}></div>
                
                {mailSentConfirmationInfo && <Eyebrow className={'ob-congrats__mailsent-info'}>{mailSentConfirmationInfo}</Eyebrow>}

                <Button className={'ob-congrats__go-to-profile'} tag={'a'} href={goToProfileLink.fields.url}>{goToProfileLink.fields.title}</Button>

                <button className={'ob-congrats__resend-email-link'} onClick={openModal}>{resendEmailLabel}</button>
                <div className={'ob-congrats__terms-and-condition'}  dangerouslySetInnerHTML={{__html: termsAndConditionsText}}></div>
                <ResponsiveImage document={environmentRecycleIcon} />
                <div className={'ob-congrats__terms-and-condition ob-congrats__terms-and-condition-bottom'}dangerouslySetInnerHTML={{__html: environmentalNoticeText}} ></div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={closeModalTimeoutMS}
                    style={customStyles}
                    htmlOpenClassName={'ob-profile__modal-overlay ob-congrats__modal-overlay'}
                >
                    <div className={'ob-profile__modal-container'}>
                        <div>
                            <p className={'ob-profile__modal-title'}>
                                {modalTitle}
                            </p>
                        </div>
                        <div className={'ob-congrats__modal-button-container'}>
                            <SubmitButton
                                buttonText={confirmLabel}
                                isSubmitting={isSubmitting}
                                onClick={resendNotificationEmail}
                            />
                            <Button
                                textTheme={true}
                                className={'ob-profile__modal-cancel-btn'}
                                onClick={closeModal}
                            >
                                {cancelLabel}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </JanrainForm>
    )
}
