import React, { useContext, useEffect, useState } from 'react';
import Block from '../../adapters/helpers/Block';
import Heading from '../Heading/Heading';
import BodyText from '../BodyText/BodyText';
import SpotlightVideoPlayer from '../SpotlightVideoPlayer/SpotlightVideoPlayer';
import PropTypes from 'prop-types'
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import Image from '../Image/Image';
import Icon from '../Icon/Icon';
import Modal from 'react-modal';
import {SpotlightContentVideoConstants} from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'

function SpotlightContentVideo(props) {

    const valueOf = (name) => {
        let value = undefined;
        if (name && block && block.getFieldValue(name) !== undefined) {
            value = block.getFieldValue(name);
        }
        return value;
    };

    /*eslint-disable */
    const generateContentBlockState = () => {
        let isMedium = props.isMedium;
    };
    /*eslint-enable */

    const isActiveBlock = (index) => {
        return props.index === index;
    };

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            border              : 'none',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            padding             : '45px 20px 20px',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            transform           : 'translate(-50%, -50%)',
            maxWidth            : '123.6rem',
            width               : '90%'
        }
    };

    const block = new Block(props);
    const introText = valueOf(SpotlightContentVideoConstants.introText);
    const introLogo = valueOf(SpotlightContentVideoConstants.introLogo);
    const title = valueOf(SpotlightContentVideoConstants.title);
    const titleLevel = valueOf(SpotlightContentVideoConstants.titleLevel);
    const nextFrameOnVideoEnd = valueOf(SpotlightContentVideoConstants.nextFrameOnVideoEnd);
    const introDurationSecond = valueOf(SpotlightContentVideoConstants.introDurationSecond);
    const anchorId = block.getAnchorId();
    const playButtonColor = valueOf(SpotlightContentVideoConstants.playButtonColor) || SpotlightContentVideoConstants.white;
    const mobileFullSize = valueOf(SpotlightContentVideoConstants.mobileFullSize);
    const mobileRatioBreakpoint = 1.5;
    const playVideoLabel = valueOf(SpotlightContentVideoConstants.playLabel);
    const pauseVideoLabel = valueOf(SpotlightContentVideoConstants.pauseLabel);
    const resetVideoLabel = valueOf(SpotlightContentVideoConstants.resetLabel);
    const openTranscriptLabel = valueOf(SpotlightContentVideoConstants.openTranscriptLabel);
    const closeTranscriptLabel = valueOf(SpotlightContentVideoConstants.closeTranscriptLabel);
    const CCPALabel = props?.document?.fields?.CCPALabel

    /*eslint-disable */
    let [blockState, setBlockState] = useState(generateContentBlockState());
    /*eslint-enable */

    let className = SpotlightContentVideoConstants.content + block.getFieldValue(SpotlightContentVideoConstants.classNames);
    const isWhiteText = className.includes('white-text');
    let [show] = useState('');
    let [removeIntro, setRemoveIntro] = useState('');

    const video = block.getFields().video.fields;
    const mobileVideo = block.getFields().mobileVideo ? block.getFields().mobileVideo.fields : null;

    const textContainerHasPadding = valueOf(SpotlightContentVideoConstants.textContainerHasPadding);

    const context = useContext(OnePageScrollContext);
    const { currentIndex } = context;

    useEffect(() => {
        if (props.isMedium) {
            setBlockState(generateContentBlockState());
        }
    }, [props.isMedium]);

    useEffect(() => {
        if (introDurationSecond) {
            let timeout = setTimeout(function () {
                setRemoveIntro(SpotlightContentVideoConstants.hide);
            }, (introDurationSecond*1000));
            return () => clearTimeout(timeout);
        }
    }, []);

    return (
        <section className={`ob-spotlightContentBlock ob-spotlightVideo ${className} ${mobileFullSize === false ? 'mobile-letterbox' : '' }`}
            id={anchorId}
            data-isactiveblock={isActiveBlock(currentIndex)}
            style={{
                pointerEvents: isActiveBlock(currentIndex) ? SpotlightContentVideoConstants.auto : SpotlightContentVideoConstants.none
            }}>

            <div className={`ob-spotlightContentBlock-wrapper ${show}`}>

                { introText && introLogo &&
                    <div className={`ob-spotlightVideo__intro ${removeIntro}`}>
                        <Image image={introLogo} className='ob-spotlightVideo__intro-image'/>
                        <BodyText whiteText={isWhiteText}>{introText}</BodyText>
                    </div>
                }

                <div className={`ob-spotlightContentBlock-textContent
                                ${textContainerHasPadding ? ' hasPadding' : ''}`}>

                    <Heading whiteText={isWhiteText} tag={titleLevel ? `h${titleLevel}` : 'h2'} className="ob-display-2-xl">{title}</Heading>

                    <BodyText whiteText={isWhiteText}>{valueOf(SpotlightContentVideoConstants.description)}</BodyText>
                </div>
            </div>

            {video &&
                <div className="ob-spotlightVideo-container">
                    <SpotlightVideoPlayer
                        playVideo={isActiveBlock(currentIndex) && context.allowVideoPlay}
                        onePageScrollContext={context}
                        nextFrameOnVideoEnd={nextFrameOnVideoEnd}
                        anchorId={anchorId}
                        video={context.viewportRatio < mobileRatioBreakpoint && mobileVideo ? mobileVideo : video} />

                        { CCPALabel &&
                            <span dangerouslySetInnerHTML={{__html: `
                                    <a class='ob-SpotlightContentVideo__CCPA' href="javascript:void(0)" onclick="Optanon.ToggleInfoDisplay();">${CCPALabel}</a>
                                `}}>
                            </span>
                        }
                </div>
            }

            {(video && isActiveBlock(currentIndex)) &&
                <div>
                    {context.showPlayButton &&
                        <button onClick={() => context.toggleVideoPlay()}
                                aria-label={context.allowVideoPlay ? pauseVideoLabel.fields.text : playVideoLabel.fields.text}
                                style={{
                                    color: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                    outlineColor: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                    height: '1.8rem',
                                    position: 'fixed',
                                    bottom: '2rem',
                                    left: '2.8rem',
                                    zIndex: '10'
                                }}
                                className="event_video_play ob-video-player-button" 
                                data-action-detail={context.allowVideoPlay ? SpotlightContentVideoConstants.paused : SpotlightContentVideoConstants.played} >
                            {context.allowVideoPlay ?
                                (
                                    <Icon name={SpotlightContentVideoConstants.spotlightPause}
                                          color={playButtonColor === 'white' ? '#ffffff' : '#0a2c54'}
                                          size={'1.3'}/>

                                ) : (
                                    <Icon name={SpotlightContentVideoConstants.spotlightPlay}
                                          color={playButtonColor === 'white' ? '#ffffff' : '#0a2c54'}
                                          size={'1.5'}/>
                                )
                            }
                        </button>
                    }

                    <button onClick={() => context.forceVideoReset()}
                            aria-label={resetVideoLabel.fields.text}
                            className="ob-video-player-button ob-video-reset-button"
                            style={{
                                color: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                outlineColor: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                position: 'fixed',
                                height: '1.8rem',
                                bottom: '2rem',
                                left: '5.2rem',
                                zIndex: '10'
                            }}>
                        <Icon name={SpotlightContentVideoConstants.spotlightReplay}
                              color={playButtonColor === 'white' ? '#ffffff' : '#0a2c54'}
                              size={'1.7'} />
                    </button>

                    {video.transcript &&
                        <button onClick={() => context.setTranscriptModal(true)}
                                aria-label={openTranscriptLabel.fields.text}
                                className="ob-video-player-button ob-video-show-transcript"
                                style={{
                                    color: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                    outlineColor: [playButtonColor === 'white' ? '#ffffff' : '#0a2c54'],
                                    position: 'fixed',
                                    height: '1.8rem',
                                    bottom: '2rem',
                                    left: '8.2rem',
                                    zIndex: '10'
                                }}>
                            <Icon name={SpotlightContentVideoConstants.spotlightTranscript}
                                  color={playButtonColor === 'white' ? '#ffffff' : '#0a2c54'}
                                  size={'1.3'}/>
                        </button>
                    }

                    {video.transcript &&
                        <Modal
                            isOpen={isActiveBlock(currentIndex) && context.openTranscriptModal}
                            onRequestClose={() => context.setTranscriptModal(false)}
                            closeTimeoutMS={250}
                            style={customModalStyles}>
                            <button className="ob-modal-close no-shadow"
                                    onClick={() => context.setTranscriptModal(false)}
                                    aria-label={closeTranscriptLabel.fields.text}>
                                <Icon name={SpotlightContentVideoConstants.close} size="2" color="#0057B8"/>
                            </button>
                            {context.viewportRatio < mobileRatioBreakpoint && mobileVideo ? mobileVideo.transcript : video.transcript}
                        </Modal>
                    }
                </div>
            }
        </section>
    );
}

export default mediaQueryHOC(SpotlightContentVideo);

SpotlightContentVideo.propTypes = {
    isMedium: PropTypes.any,
    index: PropTypes.any,
}

SpotlightContentVideo.defaultProps = {
    isMedium: PropTypes.bool,
    index: PropTypes.number
};