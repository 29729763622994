import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {FormInput, SubmitButton} from '../../../components';
import FormStore from '../generic-form.store';
import {loginValidations} from '../form.validators';
import {observer} from 'mobx-react';
import {inputs} from './inputs';
import {FormErrors} from '../index';
import useJanrainGlobalState from '../useJanrainGlobalState';
import {objectWithLabel} from '../../../../../adapters/helpers/Utils';
import {setSessionTimeoutFromLocalStorage} from '../../../helpers/localstorage.helpers';
import {sendEmailVerification} from '../../../../../adapters/model/service/api/svc';
import {triggerLoginEvent, setProfileData } from '../../../helpers/localstorage.helpers';


const loginStore = new FormStore(loginValidations, 2);

const LoginForm = ({
                       formHeading,
                       formSubHeading,
                       janrainLabels,
                       forgotPasswordPath,
                       forgotProfilePath,
                       emailValidationRegex,
                       janrainFormErrors,
                       handleLoginSuccess,
                       ariaFormIsSubmitting,
                       utmSource,
                       activateDoubleOptInEmailVerification
                   }) => {

    const { form, errors, formValidated } = loginStore;

    const [isFormProcessing, setFormProcessing] = useState(false);
    const [globalState, setGlobalState] = useJanrainGlobalState();

    const handleLogin = (e) => {
        e.preventDefault();
        setFormProcessing(true);
    };

    useEffect (() => {
        loginStore.clear();
        loginStore.errorTexts = janrainFormErrors;
    }, []);

    useEffect(() => {
        if (isFormProcessing) {
            // set call back for login
            window.janrain.events.onCaptureLoginSuccess.addHandler((data) => {
                handleLoginSuccess && handleLoginSuccess(data);
                // custom localStorage added to invalid session after 30 min
                // could be remove if widget can invalid session itself
                setSessionTimeoutFromLocalStorage();
                setProfileData();
                triggerLoginEvent();
            });
            window.janrain.events.onCaptureLoginFailed.addHandler(() => {
                setFormProcessing(false);
            });
            window.janrain.capture.ui.postCaptureForm(inputs.formName, form);
        }
    }, [isFormProcessing]);

    const renderFakeInputsToPreventAutofill = () => <>
        <label htmlFor={inputs.field.signInEmailAddress}><input type={inputs.type.email} style={{'display': 'none'}} name={inputs.field.signInEmailAddress} id={inputs.field.signInEmailAddress}/></label>
        <label htmlFor={inputs.field.currentPassword}><input type={inputs.type.password} style={{'display': 'none'}} name={inputs.field.currentPassword} id={inputs.field.currentPassword}/></label>
    </>;

    return (
        <div className="ob-login" >
            <div className="ob-form__layout ob-login__wrapper">
                <h1 className="ob-form__title" dangerouslySetInnerHTML={{__html: formHeading}}></h1>
                <h2 className="ob-form__subtitle" dangerouslySetInnerHTML={{__html: formSubHeading}}></h2>
                {globalState.isInvalidForm && <FormErrors {...globalState.formErrorResponse} predefinedErrors={janrainFormErrors}/>}
                <form className="ob-form__form"
                    name={inputs.formName}
                    onSubmit={handleLogin}
                >
                    <div className="ob-form__wrapper">
                        {renderFakeInputsToPreventAutofill()}
                        <div className="ob-form__input-wrapper">
                            <FormInput
                                label={janrainLabels.loginEmailFieldLabel}
                                inputType={inputs.type.email}
                                name={inputs.field.signInEmailAddress}
                                value={form.signInEmailAddress ? form.signInEmailAddress : ''}
                                onChange={(e) => loginStore.fieldChange(inputs.field.signInEmailAddress, e.target.value)}
                                validationErrorText={errors.signInEmailAddress}
                            />
                        </div>
                        <div className="ob-form__input-wrapper">
                            <FormInput
                                label={janrainLabels.loginPasswordFieldLabel}
                                inputType={inputs.type.password}
                                name={inputs.field.currentPassword}
                                value={form.currentPassword ? form.currentPassword : ''}
                                onChange={(e) => loginStore.fieldChange(inputs.field.currentPassword, e.target.value)}
                                validationErrorText={errors.currentPassword}
                            />
                        </div>


                    </div>

                    <div className='ob-form__login-submit-container'>
                        <div className="ob-form__forgot-pass --mobile">
                            <a href={forgotPasswordPath} data-capturescreen='forgotPassword'>{janrainLabels.loginPasswordForgotPasswordText}</a>
                        </div>
                        <SubmitButton
                            buttonText={janrainLabels.loginButtonText}
                            disabled={!formValidated}
                            isSubmitting={isFormProcessing}
                            ariaFormIsSubmitting={ariaFormIsSubmitting}
                        />
                        <div className="ob-form__forgot-pass --desktop">
                            <a href={forgotPasswordPath} data-capturescreen='forgotPassword'>{janrainLabels.loginPasswordForgotPasswordText}</a>
                        </div>
                    </div>
                    <p className="ob-form__create-profile">
                        <span>{janrainLabels.loginPasswordForgotPasswordQuestion}</span>
                        <a className='ob-form__login__link-create' href={forgotProfilePath} data-capturescreen='forgotPassword'>{janrainLabels.loginCreateAccount}</a>
                    </p>
                </form>

            </div>
        </div>
    )
};

LoginForm.propTypes = {
    formHeading: PropTypes.string.isRequired,
    formSubHeading: PropTypes.string.isRequired,
    ariaFormIsSubmitting: PropTypes.string,
    janrainLabels: PropTypes.shape({
        loginEmailFieldLabel: PropTypes.string.isRequired,
        loginPasswordFieldLabel: PropTypes.string.isRequired,
        loginPasswordForgotPasswordQuestion: PropTypes.string.isRequired,
        loginPasswordForgotPasswordText: PropTypes.string.isRequired,
        loginButtonText: PropTypes.string.isRequired,
        loginCreateAccount: PropTypes.string.isRequired,
        loginKeepMeSignedIn: PropTypes.string.isRequired
    }).isRequired,
    janrainFormErrors: PropTypes.object.isRequired,
    isCallbackInURL:  PropTypes.bool,
    callbackURL: PropTypes.string
};

export default observer(LoginForm)
