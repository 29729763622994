import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { getCanonicalURL } from '../../adapters/helpers/Utils';
import { IArticleOverview } from '../../types';


import { ArticleShareConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import MarkdownText from '../MarkdownText/MarkdownText';

export default function ArticleShare(props) {
    const { extraAttributes } = props;
    const overview = extraAttributes?.entity?.articleOverview;
    const articleShareContent = extraAttributes?.entity?.articleShareContent;

    const shareLabel = props?.document?.fields?.shareLabel?.fields?.text || ArticleShareConstants.shareTitle;
    const sharePopUpTitle = articleShareContent?.fields?.title;
    const sharePopUpBody = articleShareContent?.fields?.body;
    const sharePopUpFacebookShareText = articleShareContent?.fields?.facebookShareLabel?.fields?.text || ArticleShareConstants?.facebookAlt;
    const sharePopUpTwitterShareText = articleShareContent?.fields?.twitterShareLabel?.fields?.text || ArticleShareConstants?.twitterAlt;
    const sharePopUpCloseOverlayText = articleShareContent?.fields?.closeOverlayLabel?.fields?.text;
    const twitterShareText = overview?.fields?.twitterShareText || '';
    const button = ArticleShareConstants?.button
    const path = overview?.fields?.slug?.fields?.slug;
    const url = getCanonicalURL(path);

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterShareText)}&url=${encodeURIComponent(url)}`;

    const closeModalTimeoutMS = 250;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentShareLink, setCurrentShareLink] = useState('');
    const [eventLabel,seteventLabel] = useState('')

    const customStyles = {
        overlay: {
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(0, 0, 0, 0.5)',
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center'
        },
        content: {
            position            : 'static',
            display             : 'flex',
            maxWidth            : '560px'
        }
    };

    useEffect(() => {
        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = (url, text, labelDetails) => {
        setCurrentShareLink({url: url, text: text});
        setIsModalOpen(true);
        seteventLabel(labelDetails);
    };

    return (
        <div className='ob-article-share article-body js-modal-container'>
            <div className='ob-article-share__content'>
                <div className='ob-article-share__label'>
                    {shareLabel}
                </div>

                <ul className='ob-article-share__list'>
                    <li className='ob-article-share__item'>
                        <button
                            className='ob-article-share__link event_share'
                            data-action-detail={AnalyticsConstants.facebook}
                            onClick={() => openModal(facebookUrl, sharePopUpFacebookShareText,AnalyticsConstants.facebook)}
                            aria-label={`${sharePopUpFacebookShareText} ${button}`}
                        >
                            <img
                                className='ob-article-share__icon'
                                src={ArticleShareConstants.faceBookIcon}
                                width={24}
                                height={24}
                                alt={sharePopUpFacebookShareText}
                            />
                        </button>
                    </li>

                    <li className='ob-article-share__item'>
                        <button
                            className='ob-article-share__link event_share'
                            data-action-detail={AnalyticsConstants.twitter}
                            onClick={() => openModal(twitterUrl, sharePopUpTwitterShareText,  AnalyticsConstants.twitter)}
                            aria-label={`${sharePopUpTwitterShareText} ${button}`}
                        >
                            <img
                                className='ob-article-share__icon'
                                src={ArticleShareConstants.twitterIcon}
                                width={24}
                                height={24}
                                alt={sharePopUpTwitterShareText}
                            />
                        </button>
                    </li>
                </ul>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                closeTimeoutMS={closeModalTimeoutMS}
                style={customStyles}
            >
                <div className={'ob-article-share__modal-container'}>
                    <button aria-label={sharePopUpCloseOverlayText} className={'ob-article-share__close-btn'} onClick={closeModal}>
                        <Icon name={'close'} size={2} />
                    </button>
                    <Heading tag={'h2'}>{sharePopUpTitle}</Heading>
                    <MarkdownText value={sharePopUpBody} />

                    {currentShareLink !== '' &&
                        <Button
                            className={'ob-article-share__modal-btn event_socialmedia_exit'}
                            tag={'a'}
                            href={currentShareLink.url}
                            dataActionDetail={eventLabel}
                            target={'_blank'}
                        >
                            {currentShareLink.text}
                        </Button>
                    }
                </div>
            </Modal>
        </div>
    );
}

ArticleShare.propTypes = {
    article: PropTypes.shape({
        articleOverview: IArticleOverview,
    }),
    extraAttributes: PropTypes.object
};
