import React, { PureComponent } from 'react'
import Helmet from 'next/head'
import PropTypes from 'prop-types'
import { customJsonstringify } from '../../adapters/helpers/Utils';
import { Constants } from '../../adapters/helpers/Constants';

class BreadCrumbSEO extends PureComponent {
    constructor(props) {
        super(props)
        const { breadcrumb } = props 
        const BASE_URL = process.env.BASE_URL;
        this.itemList = breadcrumb && breadcrumb.map((breadcrumb, key) => ({
            '@type': Constants.ListItem,
            'position': (key + 1),
            'name': breadcrumb?.pageTitle,
            'item': `${BASE_URL}${breadcrumb?.linkUrl}`
        }))
    }

    render() {
        return (
            <Helmet>
                <script
                    type={Constants.typeLD}
                    dangerouslySetInnerHTML={{
                        __html: `{
                            "@context":"${Constants.context}",
                            "@type": "${Constants.breadcrumb}",
                            "itemListElement": ${customJsonstringify(this.itemList)}
                        }`
                    }}
                />
            </Helmet>
        )
    }
}

BreadCrumbSEO.propTypes = {
    breadcrumb: PropTypes.array.isRequired
}

export default BreadCrumbSEO
