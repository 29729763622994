import { validateEmail } from '../../adapters/helpers/Utils';
import { CashbackBlockConstants } from '../../adapters/helpers/Constants';

const validate = (firstName, lastName, emailInput, areaCodeOption, phoneNumber, selectedGenderOption, selectedCountryOption,
    ageValue, cityValue, campaignValues, smsCheckbox, emailCheckbox) => {
    //Email errors
    const errors = {
        firstName: '', lastName: '', emailInput: '', areaCodeOption: '', phoneNumber: '', selectedGenderOption: '', selectedCountryOption: '',
        ageValue: '', cityValue: '', campaignValues: '', smsCheckbox: '', emailCheckbox: ''
    };
    if (firstName == null || firstName == '') {
        errors.firstName = true;
    }
    if (validateEmail(emailInput)) {
        errors.emailInput = true;
    }
    //first name Errors
    if (lastName == null || lastName == '') {
        errors.lastName = true
    }
    //last name Errors
    if (areaCodeOption === CashbackBlockConstants.areaCode || areaCodeOption === '') {
        errors.areaCodeOption = true
    }
    if (phoneNumber === '' || phoneNumber.length<9) {
        errors.phoneNumber = true
    }
    if (selectedGenderOption === CashbackBlockConstants.gender || selectedGenderOption === '') {
        errors.selectedGenderOption = true
    }
    if (selectedCountryOption === CashbackBlockConstants.Country || selectedCountryOption === '') {
        errors.selectedCountryOption = true
    }
    if (ageValue === CashbackBlockConstants.age || ageValue === '') {
        errors.ageValue = true
    }
    if (cityValue === '' && selectedCountryOption === CashbackBlockConstants.UAE) {
        errors.cityValue = true
    }
    if (selectedCountryOption === CashbackBlockConstants.UAE) {
        if (cityValue === CashbackBlockConstants.city) {
            errors.cityValue = true
        }

    }
    if (campaignValues === CashbackBlockConstants.select || campaignValues === '') {
        errors.campaignValues = true
    }
    if (smsCheckbox === '' || smsCheckbox === false) {
        errors.smsCheckbox = true
    }
    if (emailCheckbox === '' || emailCheckbox === false) {
        errors.emailCheckbox = true
    }
    return errors;
}

export default validate;