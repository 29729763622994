import React, { useCallback, useState, useRef, useMemo } from 'react';
import C from 'classnames';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import { ButtonTabsNavigationConstant } from '../../adapters/helpers/Constants'
export default function ButtonTabsNavigation({tabs, selectedIndex, jumpToLabel, scrollToContent}) {

    const [navCollapsed, setNavCollapsed] = useState(true);
    const [navHeight, setNavHeight] = useState(0);
    const navList = useRef(null);

    const handleNavToggle = useCallback(
        () => { setNavCollapsed(prevState => !prevState); },
        [],
    );

    const calculateHeight = useCallback(
        () => {
            if (navList.current) {
                setNavHeight(navList.current.scrollHeight);
            }

        },
        [],
    );

    const selectedItem = useMemo(() => tabs[selectedIndex], [tabs]);

    return (
        <CSSTransition in={!navCollapsed} timeout={800} onEntering={calculateHeight} onExit={calculateHeight}>
            <nav className='ob-button-tabs-navigation'>
                <button className='ob-button-tabs-navigation-toggle' aria-expanded={!navCollapsed} type='button' aria-hidden={navCollapsed} onClick={handleNavToggle}>
                    <span className='ob-button-tabs-navigation-link-name'>
                        {jumpToLabel ?
                            jumpToLabel?.fields?.text
                            :
                            selectedItem?.text
                        }
                    </span>

                    <Icon ariaHidden={navCollapsed} className={`ob-button-tabs-navigation-chevron ${!navCollapsed ? 'ob-button-tabs-navigation-chevron--rotated' : ''}`} name={ButtonTabsNavigationConstant.chevronDown} size={1.6} />
                </button>

                <div className='ob-button-tabs-navigation-collapsible' style={{ height: navHeight }}>
                    <ul className='ob-button-tabs-navigation-list' ref={ navList } role={ButtonTabsNavigationConstant.tablist} aria-label={ ButtonTabsNavigationConstant.replacementBrushHead }>
                        {tabs.map((tab, i) => (
                            <li key={i}
                                className={C('ob-button-tabs-navigation-item', {
                                    ['ob-button-tabs-navigation-item--active']: i === selectedIndex,
                                })}
                                role={ButtonTabsNavigationConstant.tab}
                                aria-selected={i === selectedIndex ? true : false} 
                                aria-hidden={false}
                                id={`${ButtonTabsNavigationConstant.panel} ${i+1}`}
                                tabIndex={i === selectedIndex? '0':'-1'}
                                aria-label={tab?.text}
                            >
                                <a className='event_internal_link ob-button-tabs-navigation-link'
                                   data-action-detail={tab?.text}
                                   onClick={event => scrollToContent ? scrollToContent(event, tab?.link) : null}
                                   href={tab?.link}
                                >
                                    <span className='ob-button-tabs-navigation-link-name'>
                                        {tab?.text}
                                    </span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </CSSTransition>
    );
}

ButtonTabsNavigation.propTypes = {
    tabs: PropTypes.any,
    selectedIndex: PropTypes.any,
    jumpToLabel: PropTypes.object,
    scrollToContent: PropTypes.func
}

ButtonTabsNavigation.defaultProps = {
    tabs: [],
}
